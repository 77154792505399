import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		height: 360,
		width: '100vw',
		backgroundImage: theme.homeImage,
		backgroundPositionY: theme.homeImageY,
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: 'center',
	},
}));
