import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: 60,
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: 40,
		[theme.breakpoints.down('md')]: {
			gap: 30,
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},

		'& .MuiListItem-root:hover': {
			backgroundColor: `${theme.palette.backgroundExtreme}!important`,
			boxShadow: `0px 0px 15px ${theme.palette.text.primary}`,
		},

		'& .MuiListItem-root': {
			width: 320,
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			padding: 20,
			backgroundColor: theme.palette.backgroundExtreme,
			gap: 10,
			[theme.breakpoints.down('md')]: {
				width: 220,
				gap: 4,
			},
			[theme.breakpoints.down('sm')]: {
				width: 'calc(100vw - 50px)',
			},
		},
	},
	actionImg: {
		width: 300,
		[theme.breakpoints.down('md')]: {
			width: 200,
		},
	},
	listTitle: {
		'& span': {
			fontWeight: 'bold',
			fontSize: '1.5rem',
			marginTop: '1rem',
		},
	},
}));
