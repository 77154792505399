import React from 'react';
import { useIntl } from '@itsa.io/web3utils';
import { List, ListItem, ListItemText } from '@itsa.io/ui';
import MainHouse from 'components/common/MainHouse';
import useStyles from 'styles/pages/Index';

const IndexPage = () => {
	const { t } = useIntl();
	const classes = useStyles();

	const actions = (
		<List className={classes.actionsContainer}>
			<ListItem button component="a" href="/tokenize-property">
				<img
					alt="tokenize-your-property"
					className={classes.actionImg}
					src="images/fund_a_home.webp"
				/>
				<ListItemText
					className={classes.listTitle}
					primary={t('page.index.tokenize_property')}
				/>
				<ListItemText primary={t('page.index.fund_a_home_description')} />
			</ListItem>
			<ListItem button component="a" href="/my-properties">
				<img
					alt="my-properties"
					className={classes.actionImg}
					src="images/sell_a_home.webp"
				/>
				<ListItemText
					className={classes.listTitle}
					primary={t('page.index.my_properties')}
				/>
				<ListItemText primary={t('page.index.my_properties_description')} />
			</ListItem>
			<ListItem button component="a" href="/view-listings">
				<img
					alt="view-listings"
					className={classes.actionImg}
					src="images/buy_a_home.webp"
				/>
				<ListItemText
					className={classes.listTitle}
					primary={t('page.index.view_listings')}
				/>
				<ListItemText primary={t('page.index.view_listings_description')} />
			</ListItem>
		</List>
	);

	return (
		<div className={classes.root}>
			<MainHouse />
			{actions}
		</div>
	);
};

export default IndexPage;
